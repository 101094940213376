:focus-visible {
  outline: none;
}

:focus {
  outline: none !important;
}

iframe {
  border: none;
}

body {
  margin: 0;
  background-color: white;
  font-family: 'Roboto Flex', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
}

h2 {
  margin-bottom: 8px;
}

ul {
  margin: 0;
  padding-left: 25px;
}

li {
  list-style-type: disc;
}

html {
  background: #f1f2f6;
}
